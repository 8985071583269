import React from 'react';
import { Row, Col, Button } from 'reactstrap';

import logo from './assets/images/logo_full.png';
import discord from './assets/images/discord.svg';
import opensea from './assets/images/opensea.svg'
import etherscan from './assets/images/etherscan.svg';

import './App.css';

/*
      <div style={{marginBottom: 20}}> 
        <Countdown className='countdown' date={1644181200000} daysInHours={true}/>
      </div>
*/

function App() {
  return (
    <>
     <div className="App mint">
    <div class="social">
      <a href="https://discord.com/invite/metavatars" target="_blank" rel="noreferrer">
        <img src={discord} alt="discord"/>
      </a>
      <a href="https://etherscan.io/address/0xd7e5CB2C74E72B1F13686510A6a5131701fA9681" target="_blank" rel="noreferrer">
        <img src={etherscan} alt="discord"/>
      </a>
      <a href="https://opensea.io/collection/metavatars-origins" target="_blank" rel="noreferrer">
        <img src={opensea} alt="discord"/>
      </a>
    </div>
      <img className='logo' src={logo} alt="logo metavatars"/>
      <Row className="collection goWrong">
    <Col md="12">
      <Button className="walletButton" href="https://opensea.io/collection/metavatars-origins" target="_blank">
        FIND US ON OPENSEA
      </Button>
    </Col>
  </Row>
  </div>
</>
    
  );
};

export default App;